.option-wrapper .option-img
{
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
}

.option-wrapper p
{
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-family: sofia-pro;
    font-weight: 700;
}

.option-wrapper>div
{
    margin-bottom: 1rem;
    padding: 1rem;
}