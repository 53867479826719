#canvas{
  margin-bottom: 3rem;
  font-family: sofia-pro;
  padding-top: 0!important;
}

#canvas .canvas-name{
  align-items: center;
}

#canvas .canvas-name .edit-canvas-name{
  opacity:0;
}

#canvas .canvas-name:hover .edit-canvas-name, #canvas .canvas-name:active .edit-canvas-name{
  opacity:1;
}

.canvas-tile-wrapper{
  display: flex;
}

.tile-action-container {
  margin-bottom: 20px;
}

.tile-action-container .action-handler{
  opacity:0;
  /* margin-top: -3rem; */
  display: flex;
  /* justify-content: center; */
}

.tile-action-container:hover .action-handler{
  opacity:1;
}

.canvas-tile {
  min-height: 300px;
  /* width: 250px; */
  border: 1px solid #ebebeb;
  border-radius: 0.5rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  /* margin-bottom: 3rem; */
  cursor: pointer;
  height: 100%;
  padding: 0rem;
}

.canvas-tile:hover, .canvas-tile:active{
  background-color: rgba(0,0,0,0.03);
}

.canvas-tile .options-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.canvas-tile .question-tile{
  padding: 0.5rem 1rem;
  width: 100%;
}

.canvas-tile .question-tile h5{
  font-family: orpheuspro,serif;
  font-weight: 600;
  text-align: center;
  font-size: 1rem;
  margin-top: 0;
}

.canvas-tile .option{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.canvas-tile .image-icons-container{
  /* display:flex;
  justify-content: center;
  align-items: center;
  width: 100%; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  max-width: 100%;
  flex-direction: column;
}

.canvas-tile .image-icons-container .canvas-images {
  /* height: 100%; */
  /* height: -moz-available;          
  height: -webkit-fill-available;   */
  /* height: fill-available;  */
  /* height: stretch; */
  height: 100%;
  width: auto;
  /* position: absolute; */
  object-fit: cover;
}

.canvas-images-visual-moodboard{
  height: 100%;
  object-fit: cover;
}

/* for firefox */
/* @-moz-document url-prefix() {
  .canvas-tile .image-icons-container .canvas-images{
    height: 100%;
  }
} */

/* chrome */
/* @media screen and (-webkit-min-device-pixel-ratio:0){
  .canvas-tile .image-icons-container .canvas-images{        
    height: -webkit-fill-available;  
  }
} */



.canvas-tile .image-icons-container .icons-container{
  position: absolute;
  width: 100%;
  padding: 1rem;
  opacity: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

}


.canvas-tile .image-icons-container:hover .icons-container, .canvas-tile .image-icons-container:active .icons-container{
  opacity:1;
}

@media (min-width: 300px){
  .canvas-tile-wrapper{
    justify-content: center;
  }
  .canvas-tile{
    /* widows: 300px; */
  }
  .canvas-tile-2x{
    max-width:100%;
    min-width:100%;
  }
}

@media (min-width: 600px){
  .canvas-tile-wrapper{
    justify-content: flex-start;
  }
  .canvas-tile{
    /* width: 250px; */
  }
  .canvas-tile-2x{
    max-width:50%;
    min-width:50%;
  }
}

@media (min-width: 1000px){
  
}

/* spinner */
.css-1e7x1kh, .css-j7qovq, .css-1da03c{
  width:1rem!important;
  height: 1rem!important;
}

/* quill editor */
.ql-editor p{
  font-family: sofia-pro;
}
