#account
{
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-family: sofia-pro;

}

#canvas-manager
{
    padding-top: 7rem;
    padding-bottom: 1rem;
}

#canvas-manager h2
{
    text-align: left;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: bigmoore;
    font-weight: bold;

}

#canvas-manager h5
{
    font-family: bigmoore;
    font-weight: bold;

}

#account .canvas-widget>div
{
    padding: 1rem;
}


#account .canvas-widget .img-container
{
    height: 300px;
    overflow: hidden;
}

#account .canvas-widget img
{
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}

#profilePic .editWrapper
{
    background-color: #333;
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
    border-radius: 5rem;
    border: 5px solid white;
    transition: ease-out 0.2s;

}

#profilePic .editWrapper label
{
    margin-bottom: 0rem;
    padding: 1rem;
    cursor: pointer;

}

#profilePic .editWrapper img
{
    height: 1.5rem;
}

#profilePic .editWrapper:hover
{
    background-color:black;
}

#account .profileDetails
{
    text-align:  center;
}

#account .profileDetails h1
{
    text-align :center; 
    font-weight:500;
    font-size:3rem;
    margin-bottom:0.5rem;
}

#canvas-manager h2
{
    text-align: center;
}

@media (min-width:992px){
    #account .profileDetails
    {
        text-align:  left;
    }
    
    #account .profileDetails h1
    {
        text-align :left; 
    }

    
    #canvas-manager h2
    {
        text-align: left;
    }
}