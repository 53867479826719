div.sketch-controls{
    text-align: center;
    cursor: pointer;
    margin-bottom: 1rem;
}

div.sketch-controls button.btn{
    /* height: 1rem; */
    border-radius: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    min-width: 0px;
    /* width: 100%; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

div.brush{
    position: relative;
}

div.brush div{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.canvas-wrapper{
    border: 1px dashed #aaa;
}

.tool-links a{
    font-weight: 700;
}

.dropdown-menu.show{
    max-height: 300px;
    overflow-y: auto;
}

#sketch{
    font-family: sofia-pro;
}

#sketch h2{
    text-align: center;
    font-weight: 700;
    font-family: bigmoore;
    margin-bottom: 3rem;
}

#sketch ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#sketch ::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#sketch ::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
#sketch ::-webkit-scrollbar-thumb:hover {
  background: #555; 
}