#edit-tile label{
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
}

#edit-tile input{
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
}

#edit-tile .modal-body h1{
  font-family: bigmoore, serif;
  font-weight: 700;
}



#edit-tile .modal-body h2{
  font-family: bigmoore, serif;
  font-weight: 700;
}

#edit-tile .modal-body h4{
  font-family: sofia-pro;
  font-size: 1.25rem;
  margin-bottom: 3rem;
}

#edit-tile .modal-body .btn{
  margin-top: 3rem;
  background-color: #000000;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.05rem;
  font-family: sofia-pro;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

#edit-tile .modal-header{
  border:none;
}